import React from "react";
import { useSelector } from "react-redux";
import { useCallContext } from "../reducers/Call/CallProvider";
import { CallPage } from "./CallPage/CallPage";
import { ContactsPage } from "./ContactsPage/ContactsPage";
import { TranslationPage } from "./TranslationPage";
import { GroupCallPage } from "./GroupCallPage";
import { RootState } from "../reducers/store";
import { AdmissionPage } from "./AdmissionPage/AdmissionPage";
import { ErrorBoundary } from "src/components/ErrorBoundary";

// export const PageRouter = () => {
//   const { callState }: any = useCallContext();
//   const { selectedCall } = callState;

//   const { page } = useSelector((state: RootState) => state.paginationReducer);
//   // console.log("page", page);
//   const pages = [
//     <CallPage />,
//     <ContactsPage />,
//     <TranslationPage />,
//     <GroupCallPage />,
//     <AdmissionPage />,
//   ];
//   // console.log("pages", pages);
//   // console.log("selectedCall.sessionId.length", selectedCall?.session_id);

//   return selectedCall?.session_id?.length
//     ? pages[0]
//     : pages.find((el, i: number) => i === page && el) || null;
// };

export const PageRouter = () => {
  const { callState }: any = useCallContext();
  const { selectedCall } = callState;

  const { page } = useSelector((state: RootState) => state.paginationReducer);

  const pages = [
    <CallPage />,
    <ContactsPage />,
    <TranslationPage />,
    <GroupCallPage />,
    <AdmissionPage />,
  ];

  const currentPage = selectedCall?.session_id?.length ? 0 : page;

  const CurrentPageComponent = selectedCall?.session_id?.length
    ? pages[0]
    : pages.find((_, i: number) => i === page);

  return CurrentPageComponent ? (
    <ErrorBoundary resetKey={currentPage}>{CurrentPageComponent}</ErrorBoundary>
  ) : null;
};

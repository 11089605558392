import React, { Component, ReactNode } from "react";
import { Typography } from "@material-ui/core";

import Logo from "../../Logo";

interface ErrorBoundaryProps {
  children: ReactNode;
  resetKey?: string | number;
  fallback?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage: string | null;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, errorMessage: null };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true, errorMessage: null };
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    if (prevProps.resetKey !== this.props.resetKey) {
      this.setState({ hasError: false, errorMessage: null });
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error("ErrorBoundary caught an error:", error, errorInfo);
    this.setState({ errorMessage: error.message || "Something went wrong." });
  }

  render() {
    if (this.state.hasError) {
      return (
        this.props.fallback || (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",gap:'0.5rem'
            }}
          >
            <Logo />
            <Typography variant="h3">Something went wrong.</Typography>
            <Typography variant="body1">{this.state.errorMessage || "Please try again."}</Typography>
          </div>
        )
      );
    }
    return this.props.children;
  }
}

import React from "react";

export function useHotkey(combo: string, callback: () => void) {
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const isMac = navigator.platform.indexOf("Mac") !== -1;
      const keys = combo.split("+");
      const isCombinationPressed = keys.every((key) => {
        switch (key.toLowerCase()) {
          case "ctrl":
            return isMac ? event.metaKey : event.ctrlKey; // MetaKey is used for Cmd on Mac
          case "shift":
            return event.shiftKey;
          case "alt":
            return event.altKey;
          default:
            return event.key.toLowerCase() === key.toLowerCase();
        }
      });

      if (isCombinationPressed) {
        callback();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [combo, callback]);
}

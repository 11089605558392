import { ButtonBase, makeStyles, Typography } from "@material-ui/core";
import { Dispatch } from "@reduxjs/toolkit";
import React, { useCallback } from "react";
// import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

// import { useUnixTimeFormat } from "src/hooks";
import { selectAllGroupCalls } from "src/reducers/GroupCall";
import { setCurrentGroupSession } from "src/reducers/GroupCall/GroupCallSlice";
// import { GroupCallService } from "src/services/GroupCallService";
import { GroupTranslation } from "src/types";
// import CountdownTimer from "src/utils/CountdownTimer";
import { CallService } from "src/services/CallService";
import moment from "moment";
import momenttz from "moment-timezone";

import "moment/locale/uk";
// import { useTranslation } from "react-i18next";
import { AuthService } from "src/services/AuthService";
import { switchPage } from "src/reducers/Pagination/PaginationSlice";
import { useTranslation } from "react-i18next";
import { Timezones } from "src/constants/Timezones";

const useStyles = makeStyles(() => ({
  animatedItem: {
    animation: `$call 500ms steps(1) infinite`,
  },
  "@keyframes call": {
    "0%": {
      backgroundColor: "#43A047",
    },
    "50%": {
      backgroundColor: "#fff",
    },
    "100%": {
      backgroundColor: "#43A047",
    },
  },
}));

export default function RenderGroupSessions() {
  const groupCalls = useSelector(selectAllGroupCalls);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (groupCalls.length === 0) {
    return null;
  }
  return (
    <>
      <Typography
        style={{
          backgroundColor: "rgb(33, 150, 243)",
          color: "#fff",
          textAlign: "center",
          position: "sticky",
          top: 0,
          zIndex: 300,
        }}
        variant="h6"
      >
        {t("routes./groupCalls")}
      </Typography>
      {[...groupCalls].map((session: GroupTranslation) => {
        return <GroupSessionItem key={session.id} session={session} dispatch={dispatch} />;
      })}
    </>
  );
}

export function GroupSessionItem({
  session,
  dispatch,
}: {
  session: GroupTranslation;
  dispatch: Dispatch<ReturnType<typeof setCurrentGroupSession>>;
}) {
  // const { t } = useTranslation();
  const classes = useStyles();
  const endTime = moment
    .utc(session.scheduled_at, "YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    .clone()
    .add(session.length, "seconds");
  const isAuth = AuthService.getUser();

  const duration = moment.duration(session.length, "seconds");
  const hours = duration.hours();
  const minutes = duration.minutes();
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  const isEnded = moment().isAfter(endTime) && Boolean(session.ended_at);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const { page } = useSelector(
    (state: { paginationReducer: { page: number } }) => state.paginationReducer
  );

  const handleJoinToRoom = async (session: GroupTranslation) => {
    try {
      // console.log("session", session);
      if (!session) return;
      if (page !== 3) {
        // @ts-ignore-next-line
        dispatch(switchPage(3));
      }
      setIsDisabled(true);
      const { call, success, message }: any = await CallService.answerGroupWebProCall(
        session.session_id
      );

      if (!success) {
        toast.error(message, {
          style: { boxShadow: "none", border: "1px solid black" },
        });
        return;
      }

      dispatch(
        setCurrentGroupSession({
          ...session,
          api_key: call.api_key,
          token: call.token,
          session_id: call.session_id,
        })
      );
    } catch (error) {
      console.log("handleJoinToRoom", error);
    } finally {
      setTimeout(() => {
        setIsDisabled(false);
      }, 10000);
    }
  };

  // const Chip = ({ content, isActive }: { content: string; isActive: boolean }) => {
  //   return (
  //     <Box
  //       style={{
  //         borderRadius: 4,
  //         padding: 4,
  //         backgroundColor: isActive ? "#2196F3" : "lightgray",
  //         color: isActive ? "#fff" : "#111",
  //       }}
  //     >
  //       {content}
  //     </Box>
  //   );
  // };
  const getStatusClassName = useCallback(
    (sessionData: GroupTranslation) => {
      if (sessionData.status !== "new") return "";
      return classes.animatedItem;
    },
    [classes.animatedItem]
  );


  return (
    <>
      <ButtonBase
        className={getStatusClassName(session)}
        style={{
          width: "100%",
          flexDirection: "column",
          gap: 4,
          padding: 6,
          borderBottom: "1px solid #ccc",
          alignItems: "baseline",
        }}
        disabled={
          isEnded ||
          (session.status === "answered" && isAuth.id !== session.interpreter_id) ||
          isDisabled ||
          session.status === "initiated" ||
          session.status === "ended"
        }
        key={session.id}
        onClick={() => handleJoinToRoom(session)}
      >
        <Typography variant="body1">{session.title}</Typography>

        <Typography>
          {`${momenttz
            .utc(session.scheduled_at.replace("UTC", "Z"), "YYYY-MM-DDTHH:mm:ss.SSS[Z]")
            .tz(Timezones.KIEV)
            .format("DD MMMM YYYY / HH:mm")}
             ${`(${hours ? `${formattedHours}год.` : ""}${formattedMinutes}хв.)`}`}
        </Typography>
        {/* <Typography>{moment(session.scheduled_at).format("DD MMMM YYYY / HH:mm")}</Typography> */}
        <Typography variant="body1">{session.status}</Typography>
        {/* <Box style={{ display: "flex", flexDirection: "row", gap: 8 }}>
          <Chip
            isActive={session.listeners.length > 0}
            content={`${t("groupCall.listeners")}: ${session.listeners.length}`}
          />
        </Box> */}
      </ButtonBase>
    </>
  );
}

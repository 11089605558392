import { Box } from "@material-ui/core";
import React from "react";
import { useCallContext } from "../reducers/Call/CallProvider";
import { useSelector } from "react-redux";

export const TabWrapper = ({ children, value }) => {
  const { callState } = useCallContext();
  const { selectedCall, incomingCalls } = callState;
  const { page } = useSelector((state) => state.paginationReducer);
  const pagesWithTabBar = [4]; // index of page
  // const isVideoActive =
  // 	!!incomingCalls.length || !!selectedCall.sessionId.length;
  const isVideoActive = Boolean(incomingCalls?.length) || Boolean(selectedCall?.session_id?.length);

  const height = value === 1 ? 80 : 0;
  return (
    <Box
      style={{
        overflowY: "auto",
        width: 356,
        // maxHeight: pagesWithTabBar.some((el) => el === page) ? "calc(70% - 80px)" : "70%",
        // height: isVideoActive ? `calc(100% - 236px - ${height}px)` : `calc(100% - ${height}px)`,
      }}
    >
      {children}
    </Box>
  );
};

import React from "react";
import { useCallContext } from "src/reducers/Call/CallProvider";
import { CallDataType } from "src/types";
import { CallInfo } from "./RenderCalls";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHotkey, useIsOpen } from "src/hooks";

export function RenderPriorityCalls() {
  const { isOpen, toggle } = useIsOpen();
  const { callState } = useCallContext() as any;
  const { t } = useTranslation();

  useHotkey("ctrl+e", toggle);

  const priorityCalls = React.useMemo(() => {
    return callState.priorityCalls.map((session: CallDataType) => (
      <CallInfo key={session.session_id + session.id} callData={session} />
    ));
  }, [callState.priorityCalls]);

  if (callState.priorityCalls.length === 0) {
    return null;
  }

  return (
    <ul>
      <Typography
        onClick={toggle}
        style={{
          backgroundColor: "rgb(33, 150, 243)",
          color: "#fff",
          textAlign: "center",
          position: "sticky",
          top: 0,
          zIndex: 300,
          cursor: "pointer",
          userSelect: "none",
        }}
        variant="h6"
      >
        {`${t("currentCalls")}: ${callState.priorityCalls.length}`}
      </Typography>
      {isOpen && priorityCalls}
    </ul>
  );
}

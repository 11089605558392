import { makeStyles, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
// import AddTranslationIcon from "../../assets/add_translation.svg";
import { useSelector } from "react-redux";
import { useActions } from "../../hooks/useActions";
// import { HoveredFile } from "./HoveredFile";
// import DeleteIcon from "../../assets/recorder/delete_icon.svg";
// import { t } from "i18next";
import { AuthorType, MessagesType } from "../../types";
import { TranslationsService } from "../../services/TranslationsService";
// import { io } from "socket.io-client";
// import { RequestService } from "../../services/RequestService";
import { formatTime } from "../../utils/CustomDate";
import Loader from "src/components/Loader";
// import { getIdWithExtensionFromUrl } from "src/utils/getIdWithExtensionFromUrl";
import { ImageManipulator } from "../ImagePlayground";
// import VideoRecorder from "../../components/VideoRecorder";
// import { useIsOpen } from "../../hooks";
// import { TranslationRecorder } from "./TranslationRecorder";

const useStyles = makeStyles({
  previewfilesBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "10px",
    maxHeight: "75vh",
    overflowY: "auto",
    padding: 8,
  },
  translationBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
  },
  recordBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "350px",
    width: "400px",
  },
  translationFile: {
    height: "100%",
    // height: "600px",
    // width: "500px",
    // objectFit: "contain",

    // maxHeight: '800px'
  },
  deleteButton: {
    background: "#333",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "-1px",
    borderRadius: "0",
    "&:hover": {
      background: "#444",
    },
  },
  messageBlock: {
    display: "flex",
    flexDirection: "column",
    // padding: 8,
    // overflow: "hidden",
    maxHeight: "600px",
    borderRadius: 8,
    border: "1px solid #7dabd8",
    flexBasis: "70%",
    backgroundColor: "#bedfffc2",
    "& img, video": {
      maxHeight: "500px",
      // borderRadius: 8,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
  },
  client: {
    alignSelf: "start",
  },
  interpreter: {
    alignSelf: "end",
    backgroundColor: "white",
  },
  text: {
    padding: 4,
    width: "70%",
  },
  timeChip: {
    // borderTop:"1px solid red"
    textAlign: "end",
    padding: 4,
    color: "#7dabd8",
  },
});

export const TranslationFiles = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { currentTranslations, selectedId } = useSelector((state) => state.translationsReducer);
  const { setInitialMessages } = useActions();
  const [detailedImage, setDetailedImage] = React.useState(null);
  const memoTranslations = React.useMemo(() => currentTranslations, [currentTranslations]);

  const [isLoading, setIsLoading] = useState(true);

  const getMessages = async () => {
    await TranslationsService.getRequestMessages(selectedId).then((data) => {
      // console.log(data);
      // setMessages(data.messages);
      // TODO push messages
      setInitialMessages(data.messages);
      //setCurrentTranslations({ ...currentTranslations, messages: data.messages });
      // return data;
    });
    // .finally(() => {
    //   setIsLoading(false);
    // });
    // return response;
  };

  useEffect(() => {
    setIsLoading(true);
    getMessages().then(() => {
      setIsLoading(false);
    });
    // socketRef.current = io(process.env.REACT_APP_SOCKET_URL);
    // socketRef.current.on("error", console.log);
    // socketRef.current.on("interpreter-requests:message.new", (message) => {
    //   console.log("interpreter-requests:message.new", message);
    //   // setMessages((prev) => [message, ...prev]);
    //   //   let messages = this.state.messages;
    //   //   messages.push(message);
    //   //   this.setState({ messages });
    // });

    // socketRef.current.on("interpreter-requests:request.update", (message) => {
    //   if (message.id === this.props.request.id) {
    //     if (
    //       message.status === TranslationStatus.ASSIGNED &&
    //       message.interpreter_id === this.interpreterId
    //     ) {
    //       this.setState({ assigned: true });
    //     }
    //   }
    // });
    // console.log(socketRef);
  }, [selectedId]);

  const MessageContainer = React.useCallback(({ message }) => {
    const { type, content, author_type, created_at } = message;

    let DOMNode = null;

    switch (type) {
      case MessagesType.IMAGE:
      case MessagesType.FILE:
        DOMNode = (
          <div style={{ paddingTop: 12 }} className={classes.translationFile}>
            <ImageManipulator
              onExpandAction={() => {
                setDetailedImage(content);
              }}
              imageContainerStyle={{ height: 500, width: 500 }}
              imageUrl={content}
            />
          </div>
        );

        break;
      case MessagesType.TEXT:
        DOMNode = <p className={classes.text}>{content}</p>;
        break;
      case MessagesType.VIDEO:
        DOMNode = <video style={{ width: 500, height: 300 }} controls src={content} />;
        break;
      default:
        break;
    }

    const className = `${classes.messageBlock} ${
      author_type === AuthorType.CLIENT ? classes.client : classes.interpreter
    }`;
    const isText = type === MessagesType.TEXT ? { width: "70%" } : null;

    return (
      <div className={`${className} ${isText ? classes.text : ""}`}>
        {DOMNode}
        {created_at ? <p className={classes.timeChip}>{formatTime(created_at)}</p> : null}
      </div>
    );
  }, []);

  if (detailedImage) {
    return (
      <ImageManipulator
        // expandAction={() => {
        //   setDetailedImage(content);
        // }}
        wrapperStyle={{ flexGrow: 0.9 }}
        // imageContainerStyle={{ flexGrow: 1,height: '100%' }}
        imageUrl={detailedImage}
        onClose={() => setDetailedImage(null)}
      />
    );
  }
  return (
    <Box ref={ref} style={{ flex: 1 }} className={classes.previewfilesBox}>
      {isLoading ? (
        <Loader />
      ) : detailedImage ? (
        <ImageManipulator
          // expandAction={() => {
          //   setDetailedImage(content);
          // }}

          imageContainerStyle={{ flexGrow: 1 }}
          imageUrl={detailedImage}
        />
      ) : (
        memoTranslations.messages.map((message) => {
          return <MessageContainer key={message.id} message={message} />;
        })
      )}
    </Box>
  );
});

import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Timer } from "../../utils/Timer";
// import { fullHeight } from "../../utils/constants";
// import { useTranslation } from "react-i18next";
import { CallDataType } from "src/types";
import CountdownTimer from "src/utils/CountdownTimer";
import { useCallContext } from "src/reducers/Call/CallProvider";

const CallTitleInfo = React.memo(({ sessionData }: { sessionData: CallDataType }) => {
   const totalMinutesBalance = React.useMemo(() => {
     if (!sessionData || !sessionData.minutes || !Array.isArray(sessionData.minutes)) {
       return 0;
     }

     return sessionData.minutes.reduce((total, minute) => total + (minute.left || 0), 0);
   }, [sessionData]);

  const { callState } = useCallContext() as any;
  // console.log("callState.selectedCall.answered_at", callState.selectedCall);
  return (
    <Box
      sx={{
        ...styles.infoBox,
      }}
    >
      <Typography style={{ color: "white", opacity: 0.7 }} variant="h5">
        {callState.selectedCall.answered_at ? (
          <Timer start={callState.selectedCall.answered_at * 1000} />
        ) : (
          <Timer start={Date.now()} />
        )}
        {" - "}
        {sessionData.client_phone}
        {" - "}
        <CountdownTimer
          options={{ minutes: true, hours: true }}
          countdownTime={totalMinutesBalance}
        />
      </Typography>
    </Box>
  );
});

const styles = {
  // container: {
  // position: "relative",
  // width: "100%",
  // display: "flex",
  // flexDirection: "column",
  // justifyContent: "space-between",
  // justifyItems: "space-between",
  // height: fullHeight,
  // paddingTop: 16,
  // paddingBottom: 40,
  // top: "calc(-100vh + 40px)",
  // },
  infoBox: {
    padding: 4,
    borderRadius: 4,
    transition: "background-color 200ms linear",
    "&:hover": {
      backgroundColor: "black",
    },
  } as React.CSSProperties,
};

CallTitleInfo.displayName = "CallTitleInfo";

export { CallTitleInfo };

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface VideoRecorderState {
  recordedVideo: Blob | null;
  recording: boolean;
  isLoading: boolean;
  progressBar: number;
  remainingTime: number;
  readyToRecord: boolean; 
}

const initialState: VideoRecorderState = {
  recordedVideo: null,
  recording: false,
  isLoading: false,
  progressBar: 0,
  remainingTime: 0,
  readyToRecord: false, 
};

const videoRecorderSlice = createSlice({
  name: "videoRecorder",
  initialState,
  reducers: {
    setRecordedVideo(state, action: PayloadAction<Blob>) {
      state.recordedVideo = action.payload;
    },
    clearRecordedVideo(state) {
      state.recordedVideo = null;
    },
    startRecording(state) {
      state.recording = true;
    },
    stopRecording(state) {
      state.recording = false;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setProgressBar(state, action: PayloadAction<number>) {
      state.progressBar = action.payload;
    },
    setRemainingTime(state, action: PayloadAction<number>) {
      state.remainingTime = action.payload;
    },
    toggleReadyToRecord(state) {
      state.readyToRecord = !state.readyToRecord;
    },
    disactivateReadyToRecord(state) {
      state.readyToRecord = false;
    },
    clearRecorderState() {
      return initialState;
    },
  },
});

export const {
  setRecordedVideo,
  clearRecordedVideo,
  startRecording,
  stopRecording,
  setLoading,
  setProgressBar,
  setRemainingTime,
  toggleReadyToRecord,
  disactivateReadyToRecord,
  clearRecorderState,
} = videoRecorderSlice.actions;

export default videoRecorderSlice.reducer;
